

import Config from '../../Config/Config';

export const CLAIMDETAILS = `${Config.RH_URL}rest/payclaim/claimDetails/`;
export const PAYCLAIM = `${Config.RH_URL}rest/payclaim/`;
export const PAYMENTMETHODS = `${Config.RH_URL}rest/paymethod/`;
export const CLAIMPAYMENTDETAILS = `${Config.RH_URL}rest/payclaim/paymentdetails/`;
export const CLAIMPARTIALPAYMENTDETAILS = `${Config.RH_URL}rest/payclaim/partialpaymentdetails/`;
export const INSPECTIONDETAILS = `${Config.RH_URL}rest/payclaim/inspection_invoice_details/`;
export const BASERECEIPT = Config.INSP_URL;
export const RECAPCHAKEY = Config.RECAPCHAKEY;
export const PAYMENT_MODE = Config.PAYMENT_MODE;


