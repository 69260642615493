import React,{Component} from 'react';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import 'font-awesome/css/font-awesome.min.css';
import  LoadingSpinner from './Spinner';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import * as constants from '../Global/Global';
class MailCheck extends Component{
  constructor(props) {
    super(props);
    this.state = {
        claimDetails :{
        claim_id: '',
        amount: '',
        alert:'' ,
        departmentName:'',
        status:'',
        displayPayable:''
    }
  };
}

  /*
  This will life cycle method  handle the data fetch from DB
  */
  componentDidMount() {

    const invoice = this.props.match.params.id;
    const invoice_type = this.props.match.params.invoice_type;
    console.log(invoice_type);
    axios.get(constants.CLAIMDETAILS+invoice_type+'/'+invoice)
    .then((response)=> {
    const res =response.data.result;
    //console.log(res[0].amount_invoiced);
    if(invoice_type !=='inspection'){
          if( res.length === 0)
          {
            alert( 'No claim found with this invoice number');
          }
          else
          {
            const invoiceData = res.claim ;
            const currentClaimDetails = {...this.state.claimDetails};
            currentClaimDetails.amount = (1 * invoiceData.amount_invoiced).toFixed(2);;
            currentClaimDetails.departmentName = invoiceData.name;
            currentClaimDetails.invoiceValid = true;
            currentClaimDetails.status = invoiceData.status;
            currentClaimDetails.displayPayable = invoiceData.display_payable;
            this.setState({claimDetails:currentClaimDetails});

           }
    }else
    {

      if( !res)
          {
            alert( 'No invoice found with this invoice number');
          }
          else
          {
            const invoiceData = res ;
            const currentClaimDetails = {...this.state.claimDetails};
            currentClaimDetails.amount = ( 1 * invoiceData.invoice_total).toFixed(2);;
            currentClaimDetails.departmentName = invoiceData.department.name;
            currentClaimDetails.invoiceValid = true;
            currentClaimDetails.display_id = invoiceData.display_id;
            currentClaimDetails.status = invoiceData.status;
            currentClaimDetails.displayPayable = invoiceData.display_payable;

            this.setState({claimDetails:currentClaimDetails});

           }


    }       

    })
    .catch(function (error) {
      console.log(error);
    });
  };

  render ()
  {
      return (
          <Layout>
            <div className="custom-container">
              <div className="payment-wrap">
                  <div className="logo"><Link to="/"><img src={big_logo} alt=""/></Link></div>
                  <div className="pay-method">
                      <h1>{this.state.claimDetails.departmentName}</h1>
                      {! this.state.claimDetails.invoiceValid ? <LoadingSpinner />:""}
                      <h3>Invoice Amount : ${this.state.claimDetails.amount}</h3>
                      

                      
                      <div className="pay-option mail-check">
                      <div className="title">Mail a Check</div>
                          <div className=" ">
                            {
                                this.props.match.params.invoice_type=='inspection'?
                                <h3>Invoice  #{this.state.claimDetails.display_id}</h3>
                                :null
                            }
                          </div>
                          <div className="content">
                              <p>If you prefer, you can mail a check to the following address:</p>
                              <p className="address"  >{this.state.claimDetails.departmentName }{! this.state.claimDetails.departmentName ? <LoadingSpinner />:""}<br/>

                              c/o Fire Recovery USA<br/>
                              2271 Lava Ridge Ct Suite 120<br/>
                              Roseville, CA 95661-3065<br/>



                              </p>
                              <p>  Click <Link to="/">
                                         here
                                </Link> to make a new payment.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </Layout>
      );
  };
}
export default MailCheck;
