

import React from 'react';
import { Switch, Route,BrowserRouter  } from 'react-router-dom';

import Home from './Components/Payment/Home';
import PaymentMethods from './Components/Payment/PaymentMethods';
import Receipt from './Components/Payment/Receipt';
import PaymentForm from './Components/Payment/PaymentForm';
import NoMatch from './Components/Payment/NoMatch';
import MailCheck from './Components/Payment/MailCheck';
import PrintReceipt from './Components/Payment/PrintReceipt';

const Main = () => {
  return (
    <BrowserRouter  basename={'/'}>
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
    
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/paymentmethods/:invoice_type/:id' component={PaymentMethods}></Route>
      <Route exact path='/receipt/:invoice_type/:id' component={Receipt}></Route>
      <Route exact path='/paymentform/:invoice_type/:id/:type' component={PaymentForm}></Route>
      <Route exact path='/payrecepit/:id/:type' component={PrintReceipt}></Route>
      <Route exact path='/mailacheck/:invoice_type/:id' component={MailCheck}></Route>
      <Route path='*' component={NoMatch}/>
      
    </Switch>
    </BrowserRouter >
  );
}

export default Main;
