import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../../Assets/Css/bootstrap.css';
import '../../Assets/Css/style.css';
const layout = (props)=>(
  <React.Fragment>
     <Header />
      {props.children}
      <Footer />
  </React.Fragment>
);
export default layout;
