import React from 'react';

import Main from './Main';
import './App.css';

function App() {
  return (
    <React.Fragment>
            <Main />
      </React.Fragment>
  );
}

export default App;
