import React from 'react'

import { Button,Modal,Table } from 'react-bootstrap'
import Moment from 'moment';


class BootstrapModal extends React.PureComponent{


    constructor(props) {
   		super(props); 

    } 

    render(){
    	 if(!this.props.inspectionInvoiceDetails.invoice_items) {
    	 		return false;
    	 	}
		    	let dateInspected = this.props.inspectionInvoiceDetails.invoice_items.map((eachItem,index)=>{
		    		if(eachItem.type =='Inspection' && eachItem.inspections[0])  return eachItem.inspections[0].date_inspected;
		    	});
				let balanceAmount = 0;
				if ( this.props.inspectionInvoiceDetails.amount_collected > 0 ) {
					balanceAmount = ( 1 * this.props.inspectionInvoiceDetails.invoice_total - 1 * this.props.inspectionInvoiceDetails.amount_collected).toFixed(2);
				}

		    	//console.log(dateInspected);
        return(
            <div> 
                <Modal show={this.props.showHide} size="lg"> 
                    <Modal.Header closeButton onClick={() => this.props.handleModalShowHide()}>
                    <Modal.Title>Please confirm you would like to make payment for the following inspection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
					<Table striped bordered hover>
					<thead>
					<tr>					 
					<th>Inspection Date</th>	
					<td>{ dateInspected[0]?  Moment(dateInspected[0]).format('MM/DD/YYYY'):'-'}</td>				 
					</tr>
					<tr>
					<th style={{verticalAlign: "middle"}}>Business Name</th>	
					<td className="address">{ this.props.inspectionInvoiceDetails.display_subject.split('\n', 1)[0]	}</td>  			 
					</tr>
					<tr>
					<th style={{verticalAlign: "middle"}}>Address</th>	
					<td className="address">{ this.props.inspectionInvoiceDetails.billing_address.replace(/(\r\n|\r|\n)/g, '\r\n')}</td>				 
					</tr>
					<tr>
					<th>Invoice Total</th>	
					<td><span className="amount">$ { ( 1 * this.props.inspectionInvoiceDetails.invoice_total).toFixed(2)}</span></td>				 
					</tr>
					{ (balanceAmount > 0) && <tr>
						<th>Balance Amount</th>	
						<td><span className="amount">$ { balanceAmount }</span></td>				 
					</tr>}
					<tr>
					<th>Payment Amount</th>	
					<td><span className="amount">$ { ( 1 * this.props.partialAmountToPay).toFixed(2)}</span></td>				 
					</tr>					
					</thead>
		 
					</Table>

                    </Modal.Body>

                    <Modal.Footer>

                    <Button variant="secondary" onClick={() => this.props.handleModalShowHide()}>

                        Close

                    </Button>

                    <Button variant="primary" onClick={() =>this.props.handleVerify()}>

                        Verify

                    </Button>

                    </Modal.Footer>

                </Modal>


            </div>

        )

    }

    

}


export default BootstrapModal;