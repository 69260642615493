import React from 'react';

const Footer = (props)=>(
  <React.Fragment>
  <footer>
 <div className="custom-container">
     <p>2271 Lava Ridge Court Suite 120 Roseville, CA 95661-3065</p>
 </div>
</footer>
  </React.Fragment>
);
export default Footer;
