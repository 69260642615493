import React from 'react'

import { Button,Modal,Table } from 'react-bootstrap'
import Moment from 'moment';


class BootstrapModal extends React.PureComponent{


    constructor(props) {
   		super(props); 

    } 

    render(){
    	
        return(
            <div> 
                <Modal show={this.props.showHide} size="lg"> 
                    <Modal.Header closeButton onClick={() => this.props.handleModalShowHide()}>
                    <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    When paying by echeck please ensure your account is not prevented from processing this payment to Fire Recovery by debit blocks or positive pay.  If you have not approved Fire Recovery for payments through your account, please do so before processing your payment.
                    </Modal.Body>

                    <Modal.Footer>

                    <Button variant="secondary" onClick={() => this.props.handleModalShowHide()}>

                        Cancel

                    </Button>

                    <Button variant="primary" onClick={() =>this.props.handleVerify()}>

                        Okay

                    </Button>

                    </Modal.Footer>

                </Modal>


            </div>

        )

    }

    

}


export default BootstrapModal;